const { uuid } = require("uuidv4");
//import { uuid } from "uuidv4";
let cookieFbLang = "firebase-language-override";
let cookieGuestId = "guest-id";
const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortWeekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const shortMonths = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const playerPos = [
  "Goalie",
  "Right back",
  "Center back",
  "Left back",
  "Center half",
  "Defensive mid",
  "Center mid",
  "Attacking mid",
  "Left winger",
  "Right winger",
  "Center forward",
  "Left forward",
  "Right forward",
];
export function getPlayerPos(pos) {
  return playerPos[pos];
}
export function initFooter() {
  var d = new Date();
  var smYear = document.getElementById("smYear");
  if (smYear) {
    smYear.textContent = smYear.textContent.replace("$year", d.getFullYear());
  }
}

export function setDisplay(element, displayType) {
  if (!element.style) {
    element = document.getElementById(element);
  }
  element.style.display = displayType;
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function saveCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function deleteCookie(cname) {
  //document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC";
  document.cookie = cname + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function initLangCookie() {
  var lang = getCookie(cookieFbLang);
  if (lang != "") {
    saveCookie(cookieFbLang, lang, 365);
    // $(`input[name=lang][value=${lang}]`).prop("checked", true);
    // var lbl = document.querySelector(`label.${lang}`);
    // $(`#btnTranslate span`).text(lbl.textContent);
  }
  return lang;
}

export function initLangAction() {
  var btnTrans = document.getElementById("btnTranslate");

  var listRads = document.querySelectorAll(".drop-content input");
  for (let index = 0; index < listRads.length; index++) {
    const rad = listRads[index];
    rad.addEventListener("click", function () {
      var cvalue = rad.value;
      saveCookie(cookieFbLang, cvalue, 365);
      var lbl = document.querySelector(`label.${cvalue}`);
      $(`#btnTranslate span`).text(lbl.textContent);
      // var web = `../localized-files/${cvalue}/index.html`;
      // window.location.replace(web);
      location.reload();
    });
  }
}

export function removeAllLs(e) {
  if (e) {
    $(e).unbind();
  }
}

export function formatTime(date) {
  let d = new Date(date);
  var hours = d.getHours();
  var minutes = d.getMinutes();
  var ampm = hours >= 12 ? " PM" : " AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes + ampm;
}

/**
 * Format date to show day of week, month, and day
 * @param {*} date long date value
 * @returns date formarted as "Wed\nNov\n08"
 */
export function formatDate(date, isOneLine) {
  let d = new Date(date);
  let wkDay = shortWeekday[d.getDay()];
  let month = shortMonths[d.getMonth()];
  const day = d.getDate();
  return isOneLine
    ? `${wkDay}, ${month} ${day}`
    : `${wkDay}<br />${month}<br />${day}`;
}

/**
 * Request user to login
 * @param {*} msg message to show in modal
 * @param {*} search page search query location
 */
export function confirmLoginModal(msg, search) {
  let loginModal = document.getElementById("loginModal");
  if (loginModal) {
    if (msg) {
      let modMsg = loginModal.querySelector(".mod-msg");
      modMsg.textContent = msg;
    }

    setDisplay(loginModal, "flex");
  } else {
    const container = document.createElement("div");
    container.innerHTML = elConfirmLoginModal;
    loginModal = container.firstChild;

    //let modTitle = div.querySelector(".mod-title");
    let modMsg = loginModal.querySelector(".mod-msg");
    let modYes = loginModal.querySelector(".btn-yes");
    let ModNo = loginModal.querySelector(".btn-no");

    if (msg) {
      modMsg.textContent = msg;
    }
    $(modYes).on("click", function () {
      window.location.assign(`../login.html${search}`);
    });

    $(ModNo).on("click", function () {
      setDisplay(loginModal, "none");
    });

    document.body.appendChild(loginModal);
    setDisplay(loginModal, "flex");
  }
}

export function shake(div, interval = 100, distance = 10, times = 4) {
  $(div).css("position", "relative");
  for (var iter = 0; iter < times + 1; iter++) {
    $(div).animate(
      { left: iter % 2 == 0 ? distance : distance * -1 },
      interval
    );
  } //for
  $(div).animate({ left: 0 }, interval);
} //shake

export function getIsEmailValid(email) {
  let format = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
  return email.match(format);
}

export function getIsNameValid(name) {
  let format = /^[\w\-. ]{2,}$/;
  return name.match(format);
}

export function getGuestId() {
  let id = getCookie(cookieGuestId);

  return id || uuid();
}

/**
 * Show a snackbar that disappears
 * @param {*} type success, warn, error, info
 * @param {*} msg message to show
 * @param {*} time optional time in milliseconds, default is 3000
 */
export function showToast(type, msg, time) {
  let toast = document.getElementById("toast");
  let spImg, pMsg;
  if (!toast) {
    const container = document.createElement("div");
    container.innerHTML = elToast;
    toast = container.firstChild;
    document.body.appendChild(toast);
  }

  spImg = toast.querySelector("span");
  pMsg = toast.querySelector("p");

  toast.className = type;

  switch (type) {
    case "warn":
      spImg.innerHTML = warn;
      break;
    case "error":
      spImg.innerHTML = error;
      break;
    case "info":
      spImg.innerHTML = info;
      break;
    case "success":
    default:
      spImg.innerHTML = check;
      break;
  }

  pMsg.textContent = msg;
  toast.classList.add("show");

  // After 3 seconds, remove the show class from toast
  setTimeout(
    function () {
      toast.classList.remove("show");
    },
    time ? time : 3000
  );
}
/**
 * Show a confirmation dialog with message and action buttons
 * @param {*} msg Message to show
 * @param {*} onYes function to fire when yes button clicked
 */
export function showConfirmModal(title, msg, onYes) {
  let confirmModal = document.getElementById("confirmModal");
  let pTitle, pMsg, btnNo, btnYes;

  if (!confirmModal) {
    const container = document.createElement("div");
    container.innerHTML = elConfirmModal;
    confirmModal = container.firstChild;
    document.body.appendChild(confirmModal);
  }
  pTitle = confirmModal.querySelector(".mod-title");
  pMsg = confirmModal.querySelector(".mod-msg");
  btnNo = confirmModal.querySelector(".btn-no");
  btnYes = confirmModal.querySelector(".btn-yes");

  pTitle.textContent = title ? title : "Confirm";
  pMsg.textContent = msg;

  $(btnYes).on("click", function () {
    if (onYes) {
      onYes();
    }
    setDisplay(confirmModal, "none");
  });

  $(btnNo).on("click", function () {
    setDisplay(confirmModal, "none");
  });
  setDisplay(confirmModal, "flex");
}

/**
 * Get Google maps link
 * @param {*} data coordinates or address
 * @returns href link to google maps
 */
export function getMapLink(data) {
  return `http://maps.google.com/?q=${data}`;
}

export let elConfirmModal = `<div id="confirmModal" class="modal center-items">
<div class="modal-content v-flex white-bkg br8">
  <p class="mod-title bold-txt f-24">Confirm</p>
  <p class="mod-msg f-16">
    Are you sure?
  </p>
  <div class="h-flex-res mb8">
    <button class="btn-no btn-white-gray mr8">No</button>
    <button class="btn-yes btn-blue-shw">Yes</button>
  </div>
</div>
</div>`;

export let check = `<i class="fa-solid fa-circle-check" style="color: #117453;"></i>`;
export let warn = `<i class="fa-solid fa-triangle-exclamation" style="color: #ff9f1a;"></i>`;
export let error = `<i class="fa-solid fa-circle-exclamation" style="color: #ad0000;"></i>`;
export let info = `<i class="fa-solid fa-circle-info" style="color: #065ff9;"></i>`;

export let elToast = `<div id="toast" class="success">
<div class="h-flex center-items">
  <span
    ><i class="fa-solid fa-circle-check" style="color: #117453"></i
  ></span>
  <p class="ml-8">Success!</p>
</div>
</div>`;

export let elConfirmLoginModal = `<div id="loginModal" class="modal center-items">
<div class="modal-content v-flex white-bkg br8">
  <p class="mod-title bold-txt f-24">Register/Login</p>
  <p class="mod-msg f-16">
    Register or login to participate in the chat
  </p>
  <div class="h-flex-res mb8">
    <button class="btn-no btn-white-gray mr8">Later</button>
    <button class="btn-yes btn-blue-shw">Register/Login</button>
  </div>
</div>
</div>`;

export let list_msg = `<div class="msg v-flex mb-8">
<!-- name content -->
<div class="h-flex">
  <img
    src="./images/user.svg"
    alt="profile image"
    width="16px"
    height="16px"
    class="btn-circle-white-br-gray"
  />
  <div class="name flex-1">Name</div>
  <button class="bnt-more btn-circle-trans wh-24 pad-2 mr8">
    <i class="fa-solid fa-ellipsis-vertical"></i>
  </button>
</div>
<!-- Message content -->
<div class="msg-content v-flex w-fit ml30">
  <div class="h-flex white-txt-bg">
    <img
      src="images/img.svg"
      alt=""
      width="90px"
      height="90px"
      class="mr8 img-cover hide"
    />
    <div class="txt f-16">Hello</div>
  </div>
  <div class="date f-12 self-end">1:20 AM</div>
</div>
</div>`;

export let list_event = `<div class="dEvent h-flex left-rnd-white-bkg m8">
<div class="dDate green-txt bold-txt m8 f-16 self-center">
  Wed<br />Nov<br />08
</div>
<div class="v-flex flex-1 t-start ml-8">
  <div class="h-flex-res f-16">
    <div class="dTime green-txt bold-txt flex-1">
      07:29 - 09:30 PM
    </div>
    <div class="dArrive bold-txt red-txt">Arrive: <span class="spTime">07:00 PM</span></div>
  </div>

  <div class="dType">Practice</div>
  <div class="dLocation one-line">
    <i class="fa-solid fa-location-dot mr8" style="color: #0056eb"></i
    ><a href="#" target="_blank" class="aLoc green-txt"></a>
  </div>
  <div class="dNotes">Bring white uniform</div>
</div>
<button class="btnRsvp btn-green-shw self-center opas">
  RSVP
</button>
</div>`;

export let list_player = `<div class="dPlayer v-flex pos-rel m8 center-items">
<div class="content v-flex center-items pos-rel">
  <div class="bkg-overlap white-bkg-shw"></div>
  <div class="overlap-top h-flex">
    <button
      class="btn-circle-white-green-shw opas btn-edit"
      title="Edit player"
    >
      <i class="fa-solid fa-pen" style="color: #171717"></i>
    </button>
    <img
      src="./images/user.svg"
      alt="Player image"
      width="48px"
      height="48px"
      class="img-player"
    />
    <button
      class="btn-circle-white-green-shw opas btn-remove"
      title="Remove player"
    >
      <i class="fa-solid fa-trash" style="color: #1c1c1c"></i>
    </button>
  </div>
  <div class="info v-flex center-items overlap-top pad-8">
    <div class="dName bold-txt">Name</div>
    <div class="h-flex">
      <div class="dNum mr8">#12</div>
      <div class="dPos">GoalKeeper</div>
    </div>
  </div>
</div>
</div>`;

export let list_person = `<div
class="dPerson h-flex pad-8 btn-white-gray center-items t-start m8"
>
<img
  class="btn-circle-white-br-gray img-user"
  src="./images/user.svg"
  alt="Profile image"
  width="48px"
  height="48px"
/>
<div class="name bold-txt ml-8 flex-1">Name</div>
<button
  class="btnMore btn-circle-green-shw opas"
  title="More options"
>
  <i class="fa-solid fa-ellipsis-vertical"></i>
</button>
</div>`;

export let list_rsvp = `<div class="rsvp h-flex center-items white-bkg mb-8 pad-4">
<img
  src="./images/user.svg"
  alt="Profile image"
  width="40px"
  height="40px"
  class="mr8 img-player"
/>
<div class="name green-txt flex-1">Name</div>

<div class="rad-rsvp">
  <input class="no-rad" type="radio" id="radYes" name="rsvp" value="yes" />
  <label for="radYes">Yes</label>
</div>

<div class="rad-rsvp">
  <input class="no-rad" type="radio" id="radNo" name="rsvp" value="no" />
  <label for="radNo">No</label>
</div>

<div class="rad-rsvp">
  <input class="no-rad" type="radio" id="radMaybe" name="rsvp" value="maybe" checked />
  <label for="radMaybe">Maybe</label>
</div>
</div>`;
